import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/de/chapter_5/sub_2/slide1';
import Slide2 from 'src/slides/desktop/de/chapter_5/sub_2/slide2';
import WrapperMobile from 'src/slides/mobile/de/chapter_5/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Die Schlacht, die Europa und die Welt gerettet hat | Schlacht bei Warschau" lang="de" description="Der Erfolg bei Warschau sicherte Europa den Frieden für 20 Jahre." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO
      title="Die Schlacht, die Europa und die Welt gerettet hat | Schlacht bei Warschau"
      lang="de"
      description="Der Erfolg bei Warschau sicherte Europa den Frieden für 20 Jahre."
    />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
